@import "src/assets/styles/mixins";

.psbBannerWrap {
  margin: 0 auto;
  padding: 0 80px;
  padding-top: 40px;

  max-width: 1440px;
}

.psbBannerInner {
  position: relative;
}

.psbBanner {
  width: 100%;
  height: 100%;

  border-radius: 20px;
  object-fit: contain;
}

.btnInfo {
  position: absolute;
  top: 11px;
  right: 11px;
  padding: 0;

  justify-self: center;
  width: 20px;
  height: 20px;

  background-color: transparent;
  border: none;

  cursor: pointer;

  z-index: 100;
}

@media(max-width: $mobileWidth) {
  .psbBannerWrap {
    padding: 0 20px;
    padding-top: 20px;
  }

  .psbBannerInner {
    margin: 0 auto;

    max-width: 280px;
  }
}
