@font-face {
  font-family: 'Druk Cyr';
  src: local('Druk Cyr Medium'), local('DrukCyr-Medium'),
  url('DrukCyr-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Druk Cyr';
  src: local('Druk Cyr Bold'), local('DrukCyr-Bold'),
  url('DrukCyr-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'),
  url('Inter-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('OpenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('OpenSans-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('OpenSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('OpenSans-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Kobzar KS';
  src: local('Kobzar KS'), local('KobzarKS'),
  url('KobzarKS.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
