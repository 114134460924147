// Цвета
$colorMain: #ffffff;
$colorBrand: #1caed7;
$colorSecondary: #28262e;

$colorFocus: #fb4f64;

// Шрифт
$fontFamilyMain: "Panton", sans-serif;

// Брейкпоинты
$desktopWidth: 1440px;
$tabletWidth: 991px;
$mobileWidth: 768px;

$retinaDpi: 144dpi;
$retinaDppx: 1.5dppx;
