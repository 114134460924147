@import "src/assets/styles/mixins";

.modalInner {
  padding: 60px 30px;

  display: grid;
  gap: 4px;
  min-width: 280px;

  color: #232323;
  font-size: 16px;
  text-align: left;
  font-weight: 400;
  font-family: "Open Sans", "Arial", sans-serif;

  background-color: $colorMain;
  border-radius: 20px;
}
